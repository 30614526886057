import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@material-ui/core';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { conmutador, contactos } from '../../utils/tu-instituto/constants';
import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';

const Contacto = () => {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Contacto" />

      <div className={globalClasses.container}>
        <Header title="Contacto"></Header>

        <div className={globalClasses.content}>
          <div className={classes.conmutador}>
            <Typography variant="body1">Conmutador: <span>{conmutador}</span></Typography>
          </div>

          <Grid container spacing={4}>
            {contactos.map((contacto, index) => (
              <Grid key={index} item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h4" component="h2">{contacto.title}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">{contacto.address}</Typography>
                </Grid>

                {contacto.extensions && contacto.extensions.length ? <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small" aria-label="simple table dense" className={classes.table}>
                      <TableBody>
                        {contacto.extensions.map((extension, index) => (
                          <TableRow key={index}>
                            <TableCell component="td" scope="row">{extension.name}</TableCell>
                            <TableCell component="td" scope="row" style={{ color: theme.palette.primary.main, fontWeight: "bold", width: 90 }}>{extension.extension}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid> : null}
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default Contacto;